import { createSlice } from "@reduxjs/toolkit";

const activitySlice = createSlice({
    name: "activityStore",
    initialState: {
        activeStauses: {},
        lastClickedBtnText: undefined,
        isPosting: true
    },
    reducers: {
        setStatus(state, action) {
            Object.keys(state.activeStauses).forEach((key) => {
                state.activeStauses[key] = false;
            });
            action.payload.forEach((key) => {
                state.activeStauses = { ...state.activeStauses, [key]: true };
            });
        },
        setLastClickedBtnText(state, action) {
            state.lastClickedBtnText = action.payload;
        },
        setIsPosting(state, action) {
            state.isPosting = action.payload;
        }
    }
});
export const activitySliceActions = activitySlice.actions;
export default activitySlice;